import { Component, ViewEncapsulation, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuariosService } from '../usuarios.service';
import { AuthService } from 'app/services/auth.service';
import { isNullOrUndefined } from 'util';
import { AlertasService } from '../../../../services/alertas.service';
import { environment } from 'environments/environment';

const URL = environment.url;

@Component({
  selector: 'app-uploadfoto',
  templateUrl: './uploadfoto.component.html',
  styleUrls: ['./uploadfoto.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UploadfotoComponent {
  @ViewChild('fileUploadQueue', { static: false }) fileUploadQueue: any;
  url = URL;
  nombreusuario: string;
  rolusuario: string;
  avatar: string;

  ulrSubirImagen: string;
  archivoSubido = false;
  subiendoImagen = false;

  constructor(
    private _usuariosService: UsuariosService,
    public _authService: AuthService,
    public alertaService: AlertasService,
    public matDialogRef: MatDialogRef<UploadfotoComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.nombreusuario = this._data.nombreusuario;
    this.rolusuario = this._data.rolusuario;
    this.avatar = this._data.avatar;
  }

  onDoneUploading(event) {
    if (event.ok) {
      this.avatar = event.location;
    }
  }

  guardarImagen(): void {
    this._usuariosService.updateAvatar(this.avatar).then((resp: any) => {
      if (resp) {
        this.alertaService.mostrarInfoTopCenter('Foto de perfil cambiada exitosamente!', '', 1000);
        this.matDialogRef.close();
      }
    });
  }

  ocultarGuardarYsalir(option): void {
    if (option) {
      this.guardarImagen();
    } else {
      this.matDialogRef.close();
    }
  }
}
