import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';

import { ComponentesModule } from './componentes/componentes.module';
import { TokenInterceptor } from './services/token.interceptor';
import { OwlModule } from 'ngx-owl-carousel';
import { registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/es';
registerLocaleData(locale, 'es-es');

const appRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: './modulos/admin/admin.module#AdminModule'
    // canLoad: [UsuarioGuard]
  },
  {
    path: 'apps',
    loadChildren: './main/apps/apps.module#AppsModule'
    // canLoad: [UsuarioGuard]
  },
  {
    path: 'pages',
    loadChildren: './main/pages/pages.module#PagesModule'
    // canActivate: [PagesGuard],
    // canLoad: [PagesGuard]
  },
  {
    path: 'ui',
    loadChildren: './main/ui/ui.module#UIModule'
  },
  {
    path: 'documentation',
    loadChildren: './main/documentation/documentation.module#DocumentationModule'
  },
  {
    path: 'angular-material-elements',
    loadChildren: './main/angular-material-elements/angular-material-elements.module#AngularMaterialElementsModule'
  },
  {
    path: '**',
    redirectTo: 'pages/auth/login-2'
  }
];

@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OwlModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

    TranslateModule.forRoot(),
    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    // FontAwesome Icons

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    AppStoreModule,

    // Componentes Dinamicos
    ComponentesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'es-es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
