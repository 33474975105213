export const locale = {
    lang: 'mam',
    data: {
        NAV: {
            APPLICATIONS: 'Aplicaciones',
            DASHBOARDS  : 'Dashboards',
            ECOMMERCE   : 'Tienda En Linea',
            PERFIL: 'Mi Perfil',
            ACADEMY     : 'Mam',
            MAIL        : {
                TITLE: 'Correo',
                BADGE: '25'
            },
            MAIL_NGRX        : {
                TITLE: 'Correo Ngrx',
                BADGE: '13'
            },
            CHAT        : 'Chat',
            FILE_MANAGER: 'File Manager',
            CONTACTS    : 'Contactos',
            TODO        : 'To-Do',
            SCRUMBOARD  : 'Scrumboard',
            USUARIO:      `Najb'en te`,
            MODULOS:       `Tnej`,
            PARTICIPANTES: "Ula",
            FACILITADORES: `Xnaq'tzal`,
            AREAS        : `Tqiky'`,
            DOCENTE      : `Xnaq'tzal`,
            INICIO       : `Tun txi tzyet`,
            ESCUELA      : `Te xnaq'tzb'il`,
            ROL          : `Ak'a'j aq'untl`,
            CALENDARIO   : 'Calendario',
        }, 
        INICIO:{
            CONSEJODELPUEBLO: `Nuk'tibíl te tnam`,
            BIENVENIDO:       `B'an tulena`,
            ESCUELAPLURI:     `Xnaq'tzb'il kye tnam`,
            SOPORTEA:       ``,
            INICIARSESION:  `Tun txi tzyet`,
            NOTIENESCUENTA: `Minti atoksa tzu lu`,
            RECUERDAME: `Nantza weye`,
            REGISTRATE: `Toksa`,
            LOGINEXITOSO: `B'an tulena`
        },
        REGISTRAR:{
            NOMBRE: `B'iyj`,
            CREARCUENTA: `MAM- Crear Cuenta`,
            FECHANACIMIENTO: `q'ij itz'ta`,
            DPI:`Tujlal`,
            GENERO: ``,
            FEMENINO: `Qya`,
            MASCULINO: `Ichan`,
            OTRO: `Juntl`,  
            IDENTIDAD: `Txilen`,
            NIVELACADEMICO:`Jni xjapona txnaq'tzb'il`,
            DIRECCION: `Ja tume'l`,
            DEPARTAMENTO: `Nim Tnam`,
            TELEFONO: `Yolb'il`,
            CORREOELECTRONICO: `CORREO`,
            CONTRASENIAA: `Techlal`,
            CONFIRMARCONTRASENIA: `Axix techlal`,
            ACEPTAR:`MAM - ACEPTAR`,
            TERMINOSYCONDICIONES: `MAM - TERMINOS`,
            REGRESAR: `MAM - REGRESAR`
        },
        USUARIOS:{
            NOMBRES: `Biyj`,
            TELEFONOS:`Yolb'il`,
            FILTROS: `Tz'amb'il`,
            ACTIVO: `B'an chi aq'unan`,
            NOACTIVO: `Mi'n chi aq'unan`,
            BUSCARUSUARIO: `Tun tjye jun ula`,
            NUEVOUSUARIO: ``,
            APELLIDOS: `Tmuj b'iyj`,
        },
        ROLES:{
            DESCRIPCION: `Txilen`,
            VERTODOS:`Kykyeqilx`,
            NUEVOROL: `Ak'a'j aq'untl`
        },
        MODULO:{
            NUEVOMODULO: `Ak'a'j tnej`,
            NOMBREAREA: `Tbí Tqiky'`,
            NOTA: `Jin xetz tu'n`
        },
        PARTICIPANTE:{
            NOMBRECOMPLETO: `Tkyeqil Tb'i`,
            NUEVOPARTICIPANTE:"Saq ula",
            NOMBRESYAPELLIDOS: `Biyj ex tmuj b'iyj`
        },
        IDENTIDAD:{
            LADINOMESTIZO: "Mos",
            MAYA: "Qwinaqil",
            GARIFUNA: "Garifuna",
            OTRO: "Juntl"
        },
        NIVELESCOLAR:{
            NUNGUNA:`Min ti'x`,
            PRIMARIA: `Tnejel tqiky'`,
            BASICO: `Tkab' Tqiky'`,
            DIVERSIFICADO: `Toxin Tqiky`,
            UNIVERSIDAD: `Tkyajin Tqiky'`
        },
        CLASES: {
            TODASLASCLASES: `Tkyeqil txnaq'zb'ila ete' tzu lu`,
            NOMBRECLASES: `Tb'í xnaq'tzb'il`
        }
    }
};
