import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';

import { UsuariosComponent } from 'app/main/pages/usuarios/usuarios.component';
import { UsuariosService } from 'app/main/pages/usuarios/usuarios.service';
import { UsuariosUsuarioListComponent } from 'app/main/pages/usuarios/usuario-list/usuario-list.component';
import { UsuariosSelectedBarComponent } from 'app/main/pages/usuarios/selected-bar/selected-bar.component';
import { UsuariosMainSidebarComponent } from 'app/main/pages/usuarios/sidebars/main/main.component';
import { UsuariosUsuarioFormDialogComponent } from 'app/main/pages/usuarios/usuario-form/usuario-form.component';
import { PagesGuard } from 'app/main/guards/pages.guard';
import { UploadfotoComponent } from './uploadfoto/uploadfoto.component';
import { MatCardModule } from '@angular/material';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { UploadPortadaComponent } from './uploadPortada/uploadPortada.component';
import { SharedModule } from 'app/main/shared/shared.module';
import { AuthGuard } from 'app/guards2/auth.guard';

const routes = [
  {
    path: 'usuarios',
    component: UsuariosComponent,
    canActivate: [PagesGuard],
    canLoad: [PagesGuard],
    resolve: {
      usuarios: UsuariosService
    }
  }
];

@NgModule({
  declarations: [
    UsuariosComponent,
    UsuariosUsuarioListComponent,
    UsuariosSelectedBarComponent,
    UsuariosMainSidebarComponent,
    UsuariosUsuarioFormDialogComponent,
    UploadfotoComponent,
    UploadPortadaComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    MatFileUploadModule,
    SharedModule
  ],
  providers: [UsuariosService],
  entryComponents: [UsuariosUsuarioFormDialogComponent],
  bootstrap: [UploadfotoComponent, UploadPortadaComponent]
})
export class UsuariosModule {}
