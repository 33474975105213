import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PagesGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService, private router: Router) { }

  async canActivate(): Promise<boolean> {
    return await this.canLoad();
  }

  async canLoad(): Promise<boolean> {
    const resp = await this.authService.isLoggedIn();
    if (resp === false) {
      this.router.navigate(['pages/auth/login-2']);
    }

    return resp;
  }
}
