import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

import { UsuariosService } from 'app/main/pages/usuarios/usuarios.service';

@Component({
    selector   : 'selected-bar',
    templateUrl: './selected-bar.component.html',
    styleUrls  : ['./selected-bar.component.scss']
})
export class UsuariosSelectedBarComponent implements OnInit, OnDestroy
{
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    hasSelectedUsuarios: boolean;
    isIndeterminate: boolean;
    selectedUsuarios: string[];
    filterBy: string;


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UsuariosService} _usuariosService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _usuariosService: UsuariosService,
        public _matDialog: MatDialog
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._usuariosService.onSelectedUsuariosChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selectedUsuarios => {
                this.selectedUsuarios = selectedUsuarios;
                setTimeout(() => {
                    this.filterBy = this._usuariosService.filterBy;
                    this.hasSelectedUsuarios = selectedUsuarios.length > 0;
                    this.isIndeterminate = (selectedUsuarios.length !== this._usuariosService.usuarios.length && selectedUsuarios.length > 0);
                }, 0);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Select all
     */
    selectAll(): void
    {
        this._usuariosService.selectUsuarios();
    }

    /**
     * Deselect all
     */
    deselectAll(): void
    {
        this._usuariosService.deselectUsuarios();
    }
    /**
     * Delete selected usuarios
     */
    deleteSelectedUsuarios(): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = '¿Realmente está seguro de eliminar a los usuarios seleccionados?';
        if (this.filterBy === 'activos' || this.filterBy == undefined) {
            this.confirmDialogRef.componentInstance.confirmMessage = '¿Realmente está seguro de eliminar a los usuarios seleccionados?';
        } else if(this.filterBy === 'noactivos'){
            this.confirmDialogRef.componentInstance.confirmMessage = '¿Realmente está seguro de recuperar a los usuarios seleccionados?';
        } else if(this.filterBy === 'aspirantes'){
            this.confirmDialogRef.componentInstance.confirmMessage = '¿Realmente está seguro de aceptar a los aspirantes seleccionados?';
        }

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this._usuariosService.deleteSelectedUsuarios();
                }
                this.confirmDialogRef = null;
            });
    }
}
