export const locale = {
  lang: 'es',
  data: {
    NAV: {
      APPLICATIONS: 'Aplicaciones',
      DASHBOARDS: 'Dashboards',
      CALENDAR: 'Calendario',
      ECOMMERCE: 'Tienda En Linea',
      PERFIL: 'Mi Perfil',
      ACADEMY: 'Contenido',
      MAIL: {
        TITLE: 'Correo',
        BADGE: '25'
      },
      MAIL_NGRX: {
        TITLE: 'Correo Ngrx',
        BADGE: '13'
      },
      CHAT: 'Chat',
      FILE_MANAGER: 'File Manager',
      CONTACTS: 'Contactos',
      TODO: 'To-Do',
      SCRUMBOARD: 'Scrumboard',
      USUARIO: `Usuarios`,
      MODULOS: `Módulos`,
      PARTICIPANTES: 'Participantes',
      FACILITADORES: 'Facilitadores',
      AREAS: 'Areas',
      INICIO: 'Inicio',
      ESCUELA: 'Contenido',
      ROL: 'Roles',
      CALENDARIO: 'Calendario',
      ACTIVIDADES: 'Actividades',
      CHATS: 'Chats',
      REPORTES: 'Reportes'
    },
    INICIO: {
      CONSEJODELPUEBLO: `Consejo del pueblo maya`,
      BIENVENIDO: `¡Bienvenido!`,
      ESCUELAPLURI: `Escuela Plurinacional de los Pueblos`,
      SOPORTEA: `Soporte a`,
      INICIARSESION: `Iniciar Sesión`,
      NOTIENESCUENTA: `No tienes cuenta`,
      RECUERDAME: `Recuerdame`,
      REGISTRATE: `Registrate`,
      LOGINEXITOSO: `Login Exitoso`
    },
    REGISTRAR: {
      NOMBRE: `Nombre`,
      CREARCUENTA: `Crear Cuenta`,
      FECHANACIMIENTO: `Fecha de nacimiento`,
      DPI: `Documento de identificación`,
      GENERO: `Genero`,
      FEMENINO: `Femenino`,
      MASCULINO: `Masculino`,
      OTRO: `Otro`,
      IDENTIDAD: `Identidad`,
      NIVELACADEMICO: `Nivel Academico`,
      DIRECCION: `Dirección`,
      DEPARTAMENTO: `Departamento`,
      TELEFONO: `Telefono`,
      CORREOELECTRONICO: `Correo Electrónico`,
      CONTRASENIAA: `Contraseña`,
      CONFIRMARCONTRASENIA: `Confirmar Contraseña`,
      ACEPTAR: `Aceptar`,
      TERMINOSYCONDICIONES: `Terminos y condiciones`,
      REGRESAR: `Regresar`
    },
    USUARIOS: {
      NOMBRES: `Nombres`,
      TELEFONOS: `Telefonos`,
      FILTROS: `Filtros`,
      ACTIVO: `Activo`,
      NOACTIVO: `No Activo`,
      BUSCARUSUARIO: `Buscar Usuario`,
      NUEVOUSUARIO: `Nuevo Usuario`,
      APELLIDOS: `Apellidos`
    },
    ROLES: {
      DESCRIPCION: `Roles`,
      VERTODOS: `Ver Todos`,
      NUEVOROL: `Nuevo Rol`
    },
    MODULO: {
      NUEVOMODULO: `Nuevo Módulo`,
      NOMBREAREA: `Nombre Area`,
      NOTA: `Nota`
    },
    PARTICIPANTE: {
      NOMBRECOMPLETO: `Nombre Completo`,
      NUEVOPARTICIPANTE: 'Nuevo Participante',
      NOMBRESYAPELLIDOS: `Nombres y apellidos`
    },
    IDENTIDAD: {
      LADINOMESTIZO: 'Ladino/Mestizo',
      MAYA: 'Maya',
      GARIFUNA: 'Garifuna',
      OTRO: 'Otro'
    },
    NIVELESCOLAR: {
      NUNGUNA: `Ninguna`,
      PRIMARIA: `Primaria`,
      BASICO: `Básico`,
      DIVERSIFICADO: `Diversificado`,
      UNIVERSIDAD: `Universidad`
    },
    CLASES: {
      TODASLASCLASES: `Todas las clases`,
      NOMBRECLASES: `Nombre de la clase`
    }
  }
};
