import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/internal/operators';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ContactsService } from '../../modulos/admin/contacts/contacts.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-header-tabla',
  templateUrl: './header-tabla.component.html',
  styleUrls: ['./header-tabla.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class HeaderTablaComponent implements OnInit {
  @Input() titulo: string;
  @Input() textoBuscar: string;
  @Input() nombreIcono: string;
  searchInput: FormControl;
  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _contactsService: ContactsService,
  ) {
    if (!this.titulo) {
      this.titulo = 'Tabla Header Sin Titulo';
    }
    if (!this.nombreIcono) {
      this.nombreIcono = 'account_box';
    }
    if (!this.textoBuscar) {
      this.textoBuscar = 'Buscar...';
    }
    this.searchInput = new FormControl('');
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._contactsService.onSearchTextChanged.next(searchText);
            });
  }

}
