import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';
import { Router } from '@angular/router';
import { AlertasService } from 'app/services/alertas.service';

@Component({
  selector: 'chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  contacts: any[];
  chat = { id: '', dialog: [] };
  selectedContact: any;
  sidebarFolded: boolean;
  user: any; // this user
  interval: any;
  refreshTime = 3000;
  changeChat: boolean = false;

  @ViewChild('replyForm', { static: false })
  private _replyForm: NgForm;

  @ViewChild('replyInput', { static: false })
  private _replyInput: ElementRef;

  @ViewChildren(FusePerfectScrollbarDirective)
  private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

  // Private
  private _chatViewScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;
  private subscription: Subscription = new Subscription();

  /**
   * Constructor
   *
   * @param {ChatPanelService} _chatPanelService
   * @param {HttpClient} _httpClient
   * @param {FuseSidebarService} _fuseSidebarService
   */
  constructor(
    public alertasService: AlertasService,
    private _chatPanelService: ChatPanelService,
    private _httpClient: HttpClient,
    private _fuseSidebarService: FuseSidebarService,
    private router: Router
  ) {
    // Set the defaults
    this.selectedContact = null;
    this.sidebarFolded = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Load the contacts once
    this._chatPanelService.getUser().then(user => {
      this.user = user;
      this.getContacts();
    });

    this._fuseSidebarService
      .getSidebar('chatPanel')
      .foldedChanged.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(folded => {
        this.sidebarFolded = folded;
      });
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find(directive => {
      return directive.elementRef.nativeElement.id === 'messages';
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /** Http Request */
  async getContacts() {
    if (!this.user.userModules) {
      this.user.userModules = [];
    }
    return await this._chatPanelService.getContacts(this.user.userModules).then(contacts => {
      this.contacts = contacts;
      if (!this.contacts) {
        this.contacts = [];
      }
      const index = this.contacts.findIndex(contact => contact._id === this.user._id);
      if (index !== -1) {
        this.contacts.splice(index, 1);
      }
      const systemContact = this.user;
      systemContact.nombres = 'Usuario';
      systemContact.apellidos = 'Sistema';
      systemContact.avatar = 'assets/images/avatars/profile.jpg';
      this.contacts.push(systemContact);
    });
  }

  async getChat(contact) {
    return await this._chatPanelService.getUserChats(contact).then(
      chat => {
        if (contact._id) {
          this.chat.id = contact._id;
        } else {
          this.chat.id = '';
        }
        // if(chat.length > this.chat.dialog.length) {
        // There are new messages
        this.chat.dialog = chat;
        // }
        this._prepareChatForReplies();
      }
      // (httpError: any) => {
      //   this.alertasService.mostrarErrorTopStart(
      //     'Ha ocurrido un error obteniendo tus mensajes intenta refrescando la página' + httpError,
      //     'ok',
      //     5000
      //   );
      // }
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Prepare the chat for the replies
   */
  private _prepareChatForReplies(): void {
    setTimeout(() => {
      // Focus to the reply input
      // this._replyInput.nativeElement.focus();
      // Scroll to the bottom of the messages list
      if (this._chatViewScrollbar) {
        this._chatViewScrollbar.update();
        setTimeout(() => {
          this._chatViewScrollbar.scrollToBottom(0);
        });
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Fold the temporarily unfolded sidebar back
   */
  foldSidebarTemporarily(): void {
    this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
  }

  /**
   * Unfold the sidebar temporarily
   */
  unfoldSidebarTemporarily(): void {
    this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
  }

  /**
   * Toggle sidebar opened status
   */
  toggleSidebarOpen(): void {
    this._fuseSidebarService.getSidebar('chatPanel').toggleOpen();
  }

  /**
   * Decide whether to show or not the contact's avatar in the message row
   *
   * @param message
   * @param i
   * @returns {boolean}
   */
  shouldShowContactAvatar(message, i): boolean {
    return (
      message.who === this.selectedContact.id &&
      ((this.chat.dialog[i + 1] && this.chat.dialog[i + 1].who !== this.selectedContact.id) || !this.chat.dialog[i + 1])
    );
  }

  /**
   * Check if the given message is the first message of a group
   *
   * @param message
   * @param i
   * @returns {boolean}
   */
  isFirstMessageOfGroup(message, i): boolean {
    return i === 0 || (this.chat.dialog[i - 1] && this.chat.dialog[i - 1].who !== message.who);
  }

  /**
   * Check if the given message is the last message of a group
   *
   * @param message
   * @param i
   * @returns {boolean}
   */
  isLastMessageOfGroup(message, i): boolean {
    return (
      i === this.chat.dialog.length - 1 || (this.chat.dialog[i + 1] && this.chat.dialog[i + 1].who !== message.who)
    );
  }

  /**
   * Toggle chat with the contact
   *
   * @param contact
   */
  toggleChat(contact): void {
    // If the contact equals to the selectedContact,
    // that means we will deselect the contact and
    // unload the chat
    if (this.selectedContact && contact._id === this.selectedContact._id) {
      // Reset
      // this.interval = null;
      this.resetChat();
    }
    // Otherwise, we will select the contact, open
    // the sidebar and start the chat
    else {
      // Unfold the sidebar temporarily
      this.unfoldSidebarTemporarily();

      // Set the selected contact
      this.selectedContact = contact;
      // Load the chat
      this.getChat(contact._id);
      // this.interval = setInterval(() => {
      //   this.getChat(contact._id, true);
      // }, this.refreshTime);
      // this._chatPanelService.getUserChats(contact._id).then(chat => {
      //   if (contact._id) {
      //     this.chat.id = contact._id;
      //   } else {
      //     this.chat.id = '';
      //   }
      //   this.chat.dialog = chat;
      //   this._prepareChatForReplies();
      // });

      // // this._chatPanelService.getChat(contact._id).then((chat) => {
      // //     // Set the chat
      // //     this.chat = chat;
      // //     // Prepare the chat for the replies
      // //     this._prepareChatForReplies();
      // // });
    }
  }

  /**
   * Remove the selected contact and unload the chat
   */
  resetChat(): void {
    // Set the selected contact as null
    this.selectedContact = null;
    // Stop requesting new messages to selected contact
    // this.interval = null;
    // Set the chat as null
    this.chat = null;
  }

  /**
   * Reply
   */
  reply(event): void {
    event.preventDefault();

    if (!this._replyForm.form.value.message) {
      return;
    }

    // Message
    // const message = {
    //     who    : this.user._id,
    //     message: this._replyForm.form.value.message,
    //     fechacreacion: new Date().toISOString()
    // };
    const message = {
      who: this.selectedContact._id,
      message: this._replyForm.form.value.message,
      fechacreacion: new Date().toISOString()
    };

    // Add the message to the chat
    if (!this.chat.dialog) {
      this.chat.dialog = [];
    }
    this.chat.dialog.push(message);

    // Reset the reply form
    this._replyForm.reset();

    // Update the server
    this._chatPanelService.sentMessage(message);
    // Update chatLists
    const element = {
      contactId: message.who,
      name: this.selectedContact.nombres + this.selectedContact.apellidos,
      unread: 1, // +1
      lastMessage: message.message,
      lastMessageTime: message.fechacreacion
    };
    this._chatPanelService.updateUserChatLists(element);
    // this._chatPanelService.updateChat(this.chat.id, this.chat.dialog).then(response => {
    //     // Prepare the chat for the replies
    //     this._prepareChatForReplies();
    // });
  }

  irMensajes(): void {
    this.router.navigateByUrl('apps/chat');
    this.foldSidebarTemporarily();
  }
}
