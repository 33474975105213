import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    /*   {
            id: 'admin',
           title: 'Admin',
           type: 'group',
           icon: 'pages',
           children: [
               {
                   id: 'usuarios',
                   title: 'Usuarios',
                   translate: 'NAV.USUARIO'
                   type: 'item',
                   icon: 'account_box',
                   url: '/pages/usuarios'
               },
               {
                   id: 'roles',
                   title: 'Roles',
                   translate: 'NAV.ROL'
                   type: 'item',
                   icon: 'settings',
                   url: '/pages/roles'
               },
               {
                   id: 'cursos',
                   title: 'Módulos',
                   translate: 'NAV.MODULOS'
                   type: 'item',
                   icon: 'settings',
                   url: '/pages/cursos'
               },
               {
                   id: 'docentes',
                   title: 'Facilitadores',
                   translate: 'NAV.FACILITADORES'
                   type: 'item',
                   icon: 'settings',
                   url: '/pages/docentes'
               },
               {
                   id: 'areas',
                   title: 'Areas',
                   translate: 'NAV.AREAS'
                   type: 'item',
                   icon: 'settings',
                   url: '/pages/areas'
               }
           ]
       },
       {
           id: 'applications',
           title: 'Docente',
           translate: 'NAV.FACILITADORES'
           type: 'group',
           icon: 'pages',
           children: [
               {
                   id: 'inicio',
                   title: 'Inicio',
                   translate: 'NAV.INICIO'
                   type: 'item',
                   icon: 'people',
                   url: '/pages/inicio'
               },
               {
                   id       : 'academy',
                   title    : 'Escuela',
                   translate: 'NAV.ACADEMY',
                   type     : 'item',
                   icon     : 'school',
                   url      : '/apps/academy'
               }
           ]
       },
       {
           id: 'alumno',
           title: 'Alumno',
           translate: 'NAV.PARTICIPANTES'
           type: 'group',
           icon: 'pages',
           children: [
               {
                   id: 'inicio',
                   title: 'Inicio',
                   translate: 'NAV.INICIO'
                   type: 'item',
                   icon: 'people',
                   url: '/pages/inicio'
               },
               {
                   id       : 'academy',
                   title    : 'Academy',
                   translate: 'NAV.ACADEMY',
                   type     : 'item',
                   icon     : 'school',
                   url      : '/apps/academy'
               }
           ]
       } */
];
