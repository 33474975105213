import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalAlertComponent } from 'app/main/shared/modal-alert/modal-alert.component';

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  constructor(
    public _snackBar: MatSnackBar,
  ) { }

  // Start
  mostrarInfoTopStart(message: string, action: string, duration?: number): void {
    if (!duration) {
      this._snackBar.open(message, action, {
        duration,
        verticalPosition: 'top',
        horizontalPosition: 'start'
      });
    } else {
      this._snackBar.open(message, action, {
        duration: 2000,
        verticalPosition: 'top',
        horizontalPosition: 'start'
      });
    }
  }
  mostrarInfoBottomStart(message: string, action: string, duration?: number): void {
    if (!duration) {
      this._snackBar.open(message, action, {
        duration,
        verticalPosition: 'bottom',
        horizontalPosition: 'start'
      });
    } else {
      this._snackBar.open(message, action, {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'start'
      });
    }
  }

  // Center
  mostrarInfoTopCenter(message: string, action: string, duration?: number): void {
    if (!duration) {
      this._snackBar.open(message, action, {
        duration,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    } else {
      this._snackBar.open(message, action, {
        duration,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    }
  }
  mostrarInfoBottomCenter(message: string, action: string, duration?: number): void {
    if (!duration) {
      this._snackBar.open(message, action, {
        duration,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
      });
    } else {
      this._snackBar.open(message, action, {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
      });
    }
  }

  // End
  mostrarInfoTopEnd(message: string, action: string, duration?: number): void {
    if (!duration) {
      this._snackBar.open(message, action, {
        duration,
        verticalPosition: 'top',
        horizontalPosition: 'end'
      });
    } else {
      this._snackBar.open(message, action, {
        duration: 2000,
        verticalPosition: 'top',
        horizontalPosition: 'end'
      });
    }
  }
  mostrarInfoBottomEnd(message: string, action: string, duration?: number): void {
    if (!duration) {
      this._snackBar.open(message, action, {
        duration,
        verticalPosition: 'bottom',
        horizontalPosition: 'end'
      });
    } else {
      this._snackBar.open(message, action, {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'end'
      });
    }
  }

  // Danger
  mostrarErrorTopStart(message: string, action: string, duration?: number): void {
    if (!duration) {
      this._snackBar.open(message, action, {
        duration,
        verticalPosition: 'top',
        horizontalPosition: 'start',
        panelClass: ['blue-snackbar']
      });
    } else {
      this._snackBar.open(message, action, {
        duration: 2000,
        verticalPosition: 'top',
        horizontalPosition: 'start',
        panelClass: ['blue-snackbar']
      });
    }
  }
  
}
