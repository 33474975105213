import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FuseUtils } from '@fuse/utils';
import { Usuario } from 'app/main/pages/usuarios/usuario.model';
import { Rol } from '../roles/rol.model';
import { AuthService } from 'app/services/auth.service';

import alasql from 'alasql';

import * as XLSX from 'xlsx';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;

const URL = environment.url;
@Injectable({
  providedIn: 'root'
})
export class UsuariosService implements Resolve<any> {
  onusuariosChanged: BehaviorSubject<any>;
  onSelectedUsuariosChanged: BehaviorSubject<any>;
  onUserDataChanged: BehaviorSubject<any>;
  onSearchTextChanged: Subject<any>;
  onFilterChanged: Subject<any>;

  onrolesChanged: BehaviorSubject<any>;
  onmodulosChanged: BehaviorSubject<any>;
  botonReporteUsuariosDisable: Boolean;

  usuarios: Usuario[];
  roles: Rol[];
  user: any;
  selectedUsuarios: string[] = [];

  searchText: string;
  filterBy: string;
  token: string;
  headers: HttpHeaders;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, private _authService: AuthService) {
    // Set the defaults
    this.onusuariosChanged = new BehaviorSubject([]);
    this.onSelectedUsuariosChanged = new BehaviorSubject([]);
    this.onUserDataChanged = new BehaviorSubject([]);
    this.onSearchTextChanged = new Subject();
    this.onFilterChanged = new Subject();
    this.onrolesChanged = new BehaviorSubject([]);
    this.onmodulosChanged = new BehaviorSubject([]);
    this.token = this._authService.getJwtToken();
    this.headers = new HttpHeaders({
      'x-token': this.token
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getUsuarios(), this.getRoles()]).then(([files]) => {
        this.onSearchTextChanged.subscribe(searchText => {
          this.searchText = searchText;
          this.getUsuarios();
        });

        this.onFilterChanged.subscribe(filter => {
          this.filterBy = filter;
          this.getUsuarios();
        });
        resolve(true);
      }, reject);
    });
  }

  /**
   * Get usuarios
   *
   * @returns {Promise<any>}
   */
  getUsuarios(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${URL}/user/get/${this.filterBy}`).subscribe((response: any) => {
        this.usuarios = response;
        if (this.searchText && this.searchText !== '') {
          this.usuarios = FuseUtils.filterArrayByString(this.usuarios, this.searchText);
        }

        this.usuarios = this.usuarios.map(usuario => {
          return new Usuario(usuario);
        });
        this.onusuariosChanged.next(this.usuarios);
        resolve(this.usuarios);
      }, reject);
    });
  }

  /**
   * Toggle selected usuario by id
   *
   * @param id
   */
  toggleSelectedUsuario(id): void {
    if (this.selectedUsuarios.length > 0) {
      const index = this.selectedUsuarios.indexOf(id);

      if (index !== -1) {
        this.selectedUsuarios.splice(index, 1);
        // Trigger the next event
        this.onSelectedUsuariosChanged.next(this.selectedUsuarios);

        // Return
        return;
      }
    }

    this.selectedUsuarios.push(id);
    this.onSelectedUsuariosChanged.next(this.selectedUsuarios);
  }

  /**
   * Toggle select all
   */
  toggleSelectAll(): void {
    if (this.selectedUsuarios.length > 0) {
      this.deselectUsuarios();
    } else {
      this.selectUsuarios();
    }
  }

  /**
   * Select usuarios
   *
   * @param filterParameter
   * @param filterValue
   */
  selectUsuarios(filterParameter?, filterValue?): void {
    this.selectedUsuarios = [];
    // If there is no filter, select all usuarios
    if (filterParameter === undefined || filterValue === undefined) {
      this.selectedUsuarios = [];
      this.usuarios.map(usuario => {
        this.selectedUsuarios.push(usuario.id);
      });
    }
    // Trigger the next event
    this.onSelectedUsuariosChanged.next(this.selectedUsuarios);
  }

  /**
   * Update usuario
   *
   * @param usuario
   * @returns {Promise<any>}
   */
  updateUsuario(usuario): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${URL}/user/update/${usuario.id}`, { ...usuario }).subscribe(response => {
        this.getUsuarios();
        resolve(response);
      });
    });
  }

  /**
   * Deselect usuarios
   */
  deselectUsuarios(): void {
    this.selectedUsuarios = [];
    // Trigger the next event
    this.onSelectedUsuariosChanged.next(this.selectedUsuarios);
  }
  /**
   * Delete usuario
   *
   * @param usuario
   */
  deleteUsuario(usuario): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${URL}/user/delete/${usuario.id}`, usuario).subscribe(response => {
        const usuarioIndex = this.usuarios.indexOf(usuario);
        this.usuarios.splice(usuarioIndex, 1);
        this.onusuariosChanged.next(this.usuarios);
      });
    });
  }

  /**
   * Delete selected usuarios
   */
  deleteSelectedUsuarios(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${URL}/user/deleteselected/${this.filterBy}`, this.selectedUsuarios).subscribe(response => {
        for (const usuarioId of this.selectedUsuarios) {
          const usuario = this.usuarios.find(_usuario => {
            return _usuario.id === usuarioId;
          });
          const usuarioIndex = this.usuarios.indexOf(usuario);
          this.usuarios.splice(usuarioIndex, 1);
        }
        this.onusuariosChanged.next(this.usuarios);

        this.deselectUsuarios();

        resolve(response);
      });
    });
  }

  getRoles(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${URL}/rol/get/activos`).subscribe((response: any) => {
        this.roles = response;
        this.roles = this.roles.map(rol => {
          return new Rol(rol);
        });

        this.onrolesChanged.next(this.roles);
        resolve(this.roles);
      }, reject);
    });
  }

  getLinkSubirImagen(): string {
    return `${URL}/user/subirimagen`;
  }

  guardarImagenYsalir(tipo: string): Promise<any> {
    const usuario = this._authService.usuario;
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${URL}/user/guardarImagen/${tipo}`, usuario).subscribe((response: any) => {
        if (response.ok) {
          this.cargarImagen();
        }
        resolve(response);
      }, reject);
    });
  }

  updateAvatar(avatar: string): Promise<any> {
    const usuario = this._authService.usuario;
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${URL}/user/uploadAvatar/${usuario._id}`, { avatar: avatar })
        .subscribe((response: any) => {
          this.cargarImagen();
          resolve(response);
        }, reject);
    });
  }

  updatePortada(portada: string): Promise<any> {
    const usuario = this._authService.usuario;
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${URL}/user/uploadPortada/${usuario._id}`, { portada: portada })
        .subscribe((response: any) => {
          this.cargarImagen();
          resolve(response);
        }, reject);
    });
  }

  cargarImagen(): void {
    this._authService.refreshToken().subscribe(res => {
      this._authService.getUsuario();
    });
  }

  verFacilitadorCursos(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${URL}/user/verFacilitadorCursos`, { headers: this.headers }).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  updateCursoParticipante(curso: string, participante: string, estado: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(`${URL}/user/updateCursoParticipante`, { curso, participante, estado }, { headers: this.headers })
        .subscribe((response: any) => {
          this.verFacilitadorCursos().then((modulos: any) => {
            this.onmodulosChanged.next(modulos.items);
          });
          resolve(response);
        }, reject);
    });
  }

  addCursoUser(idCurso: string, idParticipante: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(`${URL}/user/addCursoUser`, { idCurso, idParticipante }, { headers: this.headers })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  removeCursoUser(idCurso: string, idParticipante: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(`${URL}/user/removeCursoUser`, { idCurso, idParticipante }, { headers: this.headers })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  // Profile
  getUserByID(id: string): any {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${URL}/user/userById/${id}`, { headers: this.headers }).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  getUserModulo(id: string, modulo: string): any {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${URL}/user/usermodulo/${id}/${modulo}`, { headers: this.headers })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  reporteExcelUsuarios(): void {
    this._httpClient.get(`${URL}/user/get/all`, { headers: this.headers }).subscribe((response: any) => {
      this.botonReporteUsuariosDisable = true;
      const hoy = new Date();
      const nombreArchivo =
        'WolfReporteUsuarios' +
        hoy.getFullYear() +
        hoy.getMonth() +
        hoy.getDate() +
        '-' +
        hoy.getHours() +
        hoy.getMinutes() +
        '.xlsx';
      alasql.fn.Date = dateStr => {
        const date = new Date(dateStr);
        return date.toLocaleString();
      };

      alasql.fn.ESTADOO = estadoStr => {
        return estadoStr === true ? 'ACTIVO' : 'NO ACTIVO';
      };
      // tslint:disable-next-line:max-line-length
      alasql(
        'SELECT nombres as Nombres, apellidos as Apellidos, email as Correo, tipo->nombre as Tipo, telefono as Telefono, ESTADOO(estado) as Estado, Date(fechacreacion) as Fecha INTO XLSX("' +
          nombreArchivo +
          '",{headers:true}) FROM ?',
        [response]
      );
    });
    this.botonReporteUsuariosDisable = false;
  }

  sentSystemMessageChat(message: string, who: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(`${URL}/user/sentSystemMessageChat/`, { message, who }, { headers: this.headers })
        .subscribe((response: any) => {
          // definir alertas
          if (response.ok) {
            // this.getUserChats(message.who);
            resolve(response);
          } else {
            resolve(false);
          }
        });
    });
  }

  rechazarSolicitudParticipante(curso: string, participante: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(`${URL}/user/rechazarSolicitudParticipante`, { curso, participante }, { headers: this.headers })
        .subscribe((response: any) => {
          this.verFacilitadorCursos().then((modulos: any) => {
            this.onmodulosChanged.next(modulos.items);
          });
          resolve(response);
        }, reject);
    });
  }
}
