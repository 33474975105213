import { Component, ViewEncapsulation, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuariosService } from '../usuarios.service';
import { AuthService } from 'app/services/auth.service';
import { isNullOrUndefined } from 'util';
import { AlertasService } from '../../../../services/alertas.service';
import { environment } from 'environments/environment';

const URL = environment.url;

@Component({
  selector: 'app-uploadPortada',
  templateUrl: './uploadPortada.component.html',
  styleUrls: ['./uploadPortada.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UploadPortadaComponent {
  @ViewChild('fileUploadQueue', { static: false }) fileUploadQueue: any;
  url = URL;
  nombreusuario: string;
  rolusuario: string;
  portada: string;

  ulrSubirImagen: string;
  archivoSubido = false;
  subiendoImagen = false;

  constructor(
    private _usuariosService: UsuariosService,
    public _authService: AuthService,
    public alertaService: AlertasService,
    public matDialogRef: MatDialogRef<UploadPortadaComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.nombreusuario = this._data.nombreusuario;
    this.rolusuario = this._data.rolusuario;
    this.portada = this._data.portada;
    this.ulrSubirImagen = this._usuariosService.getLinkSubirImagen();
  }

  onDoneUploading(event) {
    if (event.ok) {
      this.portada = event.location;
    }
  }

  guardarImagen(): void {
    this._usuariosService.updatePortada(this.portada).then((resp: any) => {
      if (resp) {
        this.alertaService.mostrarInfoTopCenter('Foto de portada cambiada exitosamente!', '', 1000);
        this.matDialogRef.close();
      }
    });
  }

  ocultarGuardarYsalir(option): void {
    if (option) {
      this.guardarImagen();
    } else {
      this.matDialogRef.close();
    }
  }
}
