import { FuseUtils } from '@fuse/utils';

export class Rol
{
    id: string;
    nombre: string;
    descripcion: string;
    estado: Boolean;
    /**
     * Constructor
     *
     * @param rol
     */
    constructor(rol)
    {
        {
         //   this.id = rol.id || FuseUtils.generateGUID();
         this.id = rol._id;
            this.nombre = rol.nombre || '';
            this.descripcion = rol.descripcion || '';
            this.estado = rol.estado;
        }
    }
}
