import { FuseUtils } from '@fuse/utils';

export class Usuario
{
    id: string;
    nombres: string;
    apellidos: string;
    avatar: string;
    estado: boolean;
    email: string;
    dpi: number;
    nit: string;
    cambiocontrasena: string;
    password: string;
    pass2: string;
    tipo: {_id: string, nombre: string};
    nombrerol: string;
    telefono: string;
    participante: {
        dpi: string,
        identidad: string,
        direccion: string,
        departamento: string
        fechaNacimiento: Date,
        genero: string,
        nivelEscolar: string
    }

    /**
     * Constructor
     *
     * @param usuario
     */
    constructor(usuario)
    {
        {
         //   this.id = usuario.id || FuseUtils.generateGUID();
            this.id = usuario._id;
            this.nombres = usuario.nombres || '';
            this.apellidos = usuario.apellidos || '';
            this.avatar = usuario.avatar || 'assets/images/avatars/profile.jpg';
            this.estado = usuario.estado;
            this.email = usuario.email || '';
            this.dpi = usuario.dpi || 0;
            this.nit = usuario.nit || '';
            this.cambiocontrasena = usuario.cambiocontrasena || 'no';
            this.password = usuario.password || '';
            this.pass2 = usuario.password || '';
            this.tipo = usuario.tipo || {_id: '', nombre: ''};
            this.telefono = usuario.telefono || '';
            this.participante = usuario.participante || {};
        }
    }
}
