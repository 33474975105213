import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

import { UsuariosService } from 'app/main/pages/usuarios/usuarios.service';
import { UsuariosUsuarioFormDialogComponent } from 'app/main/pages/usuarios/usuario-form/usuario-form.component';
import { environment } from 'environments/environment';

const URL = environment.url;
@Component({
  selector: 'usuarios-usuario-list',
  templateUrl: './usuario-list.component.html',
  styleUrls: ['./usuario-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class UsuariosUsuarioListComponent implements OnInit, OnDestroy {
  @ViewChild('dialogContent', { static: false })
  dialogContent: TemplateRef<any>;
  url = URL;
  usuarios: any;
  user: any;
  dataSource: FilesDataSource | null;
  displayedColumns = ['checkbox', 'avatar', 'nombres', 'contacto', 'tipo', 'fecha', 'buttons'];
  selectedUsuarios: any[];
  checkboxes: {};
  dialogRef: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  filterBy: string;
  roles: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {UsuariosService} _usuariosService
   * @param {MatDialog} _matDialog
   */
  constructor(private _usuariosService: UsuariosService, public _matDialog: MatDialog) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.dataSource = new FilesDataSource(this._usuariosService);

    this._usuariosService.onusuariosChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(usuarios => {
      this.usuarios = usuarios;
      this.checkboxes = {};
      usuarios.map(usuario => {
        this.checkboxes[usuario.id] = false;
      });
    });

    this._usuariosService.onSelectedUsuariosChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selectedUsuarios => {
        for (const id in this.checkboxes) {
          if (!this.checkboxes.hasOwnProperty(id)) {
            continue;
          }

          this.checkboxes[id] = selectedUsuarios.includes(id);
        }
        this.selectedUsuarios = selectedUsuarios;
      });

    this._usuariosService.onUserDataChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
      this.user = user;
    });

    this._usuariosService.onFilterChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.filterBy = this._usuariosService.filterBy;

      this._usuariosService.deselectUsuarios();
    });

    //roles
    this._usuariosService.onrolesChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(roles => {
      this.roles = roles;

      this.checkboxes = {};
      roles.map(rol => {
        this.checkboxes[rol.id] = false;
      });
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Edit usuario
   *
   * @param usuario
   */
  editUsuario(usuario): void {
    this.dialogRef = this._matDialog.open(UsuariosUsuarioFormDialogComponent, {
      panelClass: 'usuario-form-dialog',
      data: {
        usuario: usuario,
        action: 'edit'
      }
    });

    this.dialogRef.afterClosed().subscribe(response => {
      if (!response) {
        return;
      }
      const actionType: string = response[0];
      const formData: FormGroup = response[1];
      switch (actionType) {
        /**
         * Save
         */
        case 'save':
          this._usuariosService.updateUsuario(formData.getRawValue());
          break;
        /**
         * Delete
         */
        case 'delete':
          this.deleteUsuario(usuario);

          break;
      }
    });
  }

  /**
   * Delete Usuario
   */
  deleteUsuario(usuario): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    if (usuario.estado === true) {
      this.confirmDialogRef.componentInstance.confirmMessage = `¿Está seguro de eliminar ${usuario.nombres}  ${usuario.apellidos}?`;
    } else {
      if (usuario.tipo._id === '5ddf8d1b812ef914f5c2ed57') {
        this.confirmDialogRef.componentInstance.confirmMessage = `¿Está seguro de aceptar ${usuario.nombres}  ${usuario.apellidos} como usuario inscrito en la academia?`;
      } else {
        this.confirmDialogRef.componentInstance.confirmMessage = `¿Está seguro de recuperar ${usuario.nombres}  ${usuario.apellidos}?`;
      }
    }
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._usuariosService.deleteUsuario(usuario);
      }
      this.confirmDialogRef = null;
    });
  }

  /**
   * On selected change
   *
   * @param usuarioId
   */
  onSelectedChange(usuarioId): void {
    this._usuariosService.toggleSelectedUsuario(usuarioId);
  }

  /**
   * Toggle star
   *
   * @param usuarioId
   */
  toggleStar(usuarioId): void {
    if (this.user.starred.includes(usuarioId)) {
      this.user.starred.splice(this.user.starred.indexOf(usuarioId), 1);
    } else {
      this.user.starred.push(usuarioId);
    }
  }
}

export class FilesDataSource extends DataSource<any> {
  /**
   * Constructor
   *
   * @param {UsuariosService} _usuariosService
   */
  constructor(private _usuariosService: UsuariosService) {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    return this._usuariosService.onusuariosChanged;
  }

  /**
   * Disconnect
   */
  disconnect(): void {}
}
