import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { FuseUtils } from '@fuse/utils';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'environments/environment';

const URL = environment.url;

@Injectable()
export class ChatPanelService {
  onUsuariosChanged: BehaviorSubject<any>;

  token: string;
  headers: HttpHeaders;

  contacts: any[];
  chats: any[];
  user: any;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, private _authService: AuthService) {
    this.onUsuariosChanged = new BehaviorSubject([]);
    this.token = this._authService.getJwtToken();
    this.headers = new HttpHeaders({
      'x-token': this.token
    });
  }

  /**
   * Loader
   *
   * @returns {Promise<any> | any}
   */

  /**
   * Get chat
   *
   * @param contactId
   * @returns {Promise<any>}
   */
  getChat(contactId): Promise<any> {
    const chatItem = this.user.chatList.find(item => {
      return item.contactId === contactId;
    });
    // Get the chat
    return new Promise((resolve, reject) => {
      // If there is a chat with this user, return that.
      if (chatItem) {
        this._httpClient.get('api/chat-panel-chats/' + chatItem.chatId).subscribe(chat => {
          // Resolve the promise
          resolve(chat);
        }, reject);
      }
      // If there is no chat with this user, create one...
      else {
        this.createNewChat(contactId).then(() => {
          // and then recall the getChat method
          this.getChat(contactId).then(chat => {
            resolve(chat);
          });
        });
      }
    });
  }

  /**
   * Create new chat
   *
   * @param contactId
   * @returns {Promise<any>}
   */
  createNewChat(contactId): Promise<any> {
    return new Promise((resolve, reject) => {
      // Generate a new id
      const chatId = FuseUtils.generateGUID();
      // Prepare the chat object
      const chat = {
        id: chatId,
        dialog: []
      };
      // Prepare the chat list entry
      const chatListItem = {
        chatId: chatId,
        contactId: contactId,
        lastMessageTime: '2017-02-18T10:30:18.931Z'
      };
      // Add new chat list item to the user's chat list
      this.user.chatList.push(chatListItem);
      // Post the created chat to the server
      this._httpClient.post('api/chat-panel-chats', { ...chat }).subscribe(() => {
        // Post the updated user data to the server
        this._httpClient.post('api/chat-panel-user/' + this.user.id, this.user).subscribe(() => {
          // Resolve the promise
          resolve(true);
        });
      }, reject);
    });
  }

  /**
   * Update the chat
   *
   * @param chatId
   * @param dialog
   * @returns {Promise<any>}
   */
  updateChat(chatId, dialog): Promise<any> {
    return new Promise((resolve, reject) => {
      const newData = {
        id: chatId,
        dialog: dialog
      };
      this._httpClient.post('api/chat-panel-chats/' + chatId, newData).subscribe(updatedChat => {
        resolve(updatedChat);
      }, reject);
    });
  }
  /**
   * Get contacts
   *
   * @returns {Promise<any>}
   */
  // getContacts(): Promise<any>
  // {
  //     return new Promise((resolve, reject) => {
  //         this._httpClient.get('api/chat-panel-contacts')
  //             .subscribe((response: any) => {
  //                 resolve(response);
  //             }, reject);
  //     });
  // }

  getContacts(modulos): Promise<any> {
    // let params = new HttpParams();
    // params = params.append('modulos', modulos.join(','));
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${URL}/user/getUserContacts`, { modulos: modulos }, { headers: this.headers })
        .subscribe((response: any) => {
          this.contacts = response;
          this.contacts = this.contacts.map(usuario => {
            return usuario;
          });
          this.onUsuariosChanged.next(this.contacts);
          resolve(this.contacts);
        }, reject);
    });
  }

  /**
   * Get user
   *
   * @returns {Promise<any>}
   */
  // getUser(): Promise<any>
  // {
  //     return new Promise((resolve, reject) => {
  //         this._httpClient.get('api/chat-panel-user')
  //             .subscribe((response: any) => {
  //                 resolve(response[0]);
  //             }, reject);
  //     });
  // }

  async getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${URL}/user/userById/local`, { headers: this.headers }).subscribe((response: any) => {
        if (response.ok) {
          this.user = response.usuario;
          this.user.chatList = [];
          const userModules: string[] = [];
          if (!this.user.modulos) {
            this.user.modulos = [];
          }
          this.user.modulos.forEach(modulo => {
            userModules.push(modulo.modulo);
          });
          this.user.userModules = userModules;
          // this.getContacts(userModules);
          resolve(response.usuario);
        }
      }, reject);
    });
  }

  getUserChats(idUsuario): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${URL}/user/getUserChats/${idUsuario}`, { headers: this.headers })
        .subscribe((response: any) => {
          const mensajes = response.items;
          resolve(mensajes);
        }, reject);
    });
  }

  sentMessage(message): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(`${URL}/user/sentMessageChat/`, { ...message }, { headers: this.headers })
        .subscribe((response: any) => {
          // definir alertas
          if (response.ok) {
            // this.getUserChats(message.who);
            resolve(response);
          } else {
            resolve(false);
          }
        });
    });
  }

  updateUserChatLists(chatList): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(`${URL}/user/updateUserChatLists/`, { chatList }, { headers: this.headers })
        .subscribe((response: any) => {
          // definir alertas
          if (response.ok) {
            this.getUserChats(chatList.contactId);
            resolve(response);
          } else {
            resolve(false);
          }
        });
    });
  }
}
