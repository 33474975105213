import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertasService } from 'app/services/alertas.service';
import { environment } from 'environments/environment';
import { S3 } from 'aws-sdk';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {
  selectedFiles: FileList;
  @Input() multiple: boolean;
  @Input() folder: string;
  @Output() queryOutput = new EventEmitter<any>();
  progress = 0;
  progressValue = '';

  showSpinner = false;

  constructor(public alertasService: AlertasService) { }

  ngOnInit() { }

  selectFile(event) {
    this.showSpinner = true;
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    this.uploadFile(file, this.folder);
  }

  uploadFile(file, folder): any {
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.region,
      maxRetries: 10,
      httpOptions: { timeout: 360000 }
    });
    const params = {
      Bucket: environment.bucketName,
      Key: folder + file.name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
      maxRetries: 10
    };
    var options = {
      // Part Size of 10mb
      partSize: 10 * 1024 * 1024,
      queueSize: 1
      // Give the owner of the bucket full control
      // ACL: 'bucket-owner-full-control'
    };
    // const stored = bucket.upload(params, options).promise();
    //for upload progress
    bucket
      .upload(params, options)
      .on('httpUploadProgress', event => {
        this.progress = Math.round((event.loaded / event.total) * 100);
        if (this.progress < 100) {
          this.progressValue = `${event.loaded} / ${event.total} bytes completados - (${this.progress}%)`;
        } else {
          this.progressValue = '';
        }
      })
      .send((err, data) => {
        if (err) {
          this.showSpinner = false;
          this.alertasService.mostrarErrorTopStart('Ha ocurrido un error' + err, 'ok', 10000);
          this.queryOutput.emit({ ok: false, location: data.Location });
          return false;
        }
        this.showSpinner = false;
        this.alertasService.mostrarInfoBottomEnd('Archivo Cargado con exito', 'ok', 3000);
        this.queryOutput.emit({ ok: true, location: data.Location });
        return true;
      });

    // return stored;
  }
}
