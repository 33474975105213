import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {
  title: string;
  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,) { 
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
  }

}
