export const locale = {
    lang: 'qeq',
    data: {
        NAV: {
            APPLICATIONS: 'Aplicaciones',
            DASHBOARDS  : 'Dashboards',
            CALENDAR    : 'Calendario',
            ECOMMERCE   : 'Tienda En Linea',
            PERFIL: 'Mi Perfil',
            ACADEMY     : ' q´eqchí',
            MAIL        : {
                TITLE: 'Correo',
                BADGE: '25'
            },
            MAIL_NGRX        : {
                TITLE: 'Correo Ngrx',
                BADGE: '13'
            },
            CHAT        : 'Chat',
            FILE_MANAGER: 'File Manager',
            CONTACTS    : 'Contactos',
            TODO        : 'To-Do',
            SCRUMBOARD  : 'Scrumboard',
            USUARIO     : `tz'iib'an b'il ulul okeb'`,
            MODULOS:       `tasal`,
            PARTICIPANTES: `tz'aqonel`,
            FACILITADORES: `k'utuneleb'`,
            AREAS        : `teep`,
            DOCENTE      : `xtiklajik`,
            INICIO       : `tzoleb'aal`,
            ESCUELA      : `xsik'b'al ulul okeb'`,
            ROL          : `ak' k'anjel`
        },
        INICIO:{
            CONSEJODELPUEBLO: `xe'toonil na'leb'`,
            BIENVENIDO:       `saasa qach'ool chek'ulb'al`,
            ESCUELAPLURI:     `xtzoleb'aal jarpaay chi tenamit`,
            SOPORTEA:       `xk'ub'`,
            INICIARSESION:  `xtiqlajik`,
            NOTIENESCUENTA: `maak'a' laa numsin ulul esil`,
            RECUERDAME: `chajultika we`,
            INICIAR: `tikib'ank`,
            REGISTRATE: `tz'iib'a aawib'`,
            LOGINEXITOSO: ` `,

        },
        REGISTRAR:{
            NOMBRE: `k'ab'a'`,
            CREARCUENTA: ``,
            FECHANACIMIENTO: `xkutankil yo'lajik`,
            DPI:`rajlil xhuhil poyanam`,
            GENERO: `xwanjik`,
            FEMENINO: `ixqilal`,
            MASCULINO: `winqilal`,
            OTRO: `jalan`,  
            IDENTIDAD: `k'utb'a wanjik`,
            NIVELACADEMICO:`xtzolb'al`,
            DIRECCION: `xk'aleb'aal`,
            DEPARTAMENTO: `teep`,
            TELEFONO: `b'oqleb'`,
            CORREOELECTRONICO: `b'oqleb'`,
            CONTRASENIA: ``,
            CONFIRMARCONTRASENIA: `xjunsutinkil tz'iib'anb'il okleb'`,
            ACEPTAR:``,
            TERMINOSYCONDICIONES: ``,
            REGRESAR: ``
        },
        USUARIOS:{
            NOMBRES: `k'ab'a'ej`,
            TELEFONOS:`b'oqleb'eb'`,
            FILTROS: `aj tz'ilb'`,
            ACTIVO: `k'aru re`,
            NOACTIVO: `ink'a' re`,
            BUSCARUSUARIO: `xsik'b'al ulul okeb'`,
            NUEVOUSUARIO: ``,
            APELLIDOS: `xkab' k'a'b'a'ej`,
        },
        ROLES:{
            DESCRIPCION: `xch'olob'ankil`,
            VERTODOS:`rilbal chijunil`,
            NUEVOROL: `ak' k'anjel`,
        },
        MODULO:{
            NUEVOMODULO: `ak' tasal`,
            NOMBREAREA: ``,
            NOTA: `reetalil`
        },
        PARTICIPANTE:{
            NOMBRECOMPLETO: `xk'ab'a'`,
            NUEVOPARTICIPANTE:"xb'een sut aj tz'aqonel",
            NOMBRESYAPELLIDOS: `k'a'b'a'ej`
        },
        IDENTIDAD:{
            LADINOMESTIZO: "Aj kaxlan chi'",
            MAYA: "aj ral ch'och'",
            GARIFUNA: "qj q'eq poyanam",
            OTRO: "jalan"
        },
        NIVELESCOLAR:{
            NUNGUNA:`maajun`,
            PRIMARIA: `xb'een tasal re tzolok`,
            BASICO: `xkab' tasal re tzolok`,
            DIVERSIFICADO: `rox raqal re tzolok`,
            UNIVERSIDAD: `xnimal ru tasal li tzolok`
        },
        CLASES: {
            TODASLASCLASES: `chi xjunil linakatzol wank arin`,
            NOMBRECLASES: `xk'ab'a' li tzolok`,
            TODOS:`rilbal chijunil`,
        },
        REPETIDOS:{

        }
    
    }
};
