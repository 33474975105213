export const locale = {
    lang: 'kaq',
    data: {
        NAV: {
            APPLICATIONS: 'Aplicaciones',
            DASHBOARDS  : 'Dashboards',
            CALENDAR    : 'Calendario',
            ECOMMERCE   : 'Tienda En Linea',
            PERFIL: 'Mi Perfil',
            ACADEMY     : 'Kaqchikel',
            MAIL        : {
                TITLE: 'Correo',
                BADGE: '25'
            },
            MAIL_NGRX        : {
                TITLE: 'Correo Ngrx',
                BADGE: '13'
            },
            CHAT        : 'Chat',
            FILE_MANAGER: 'File Manager',
            CONTACTS    : 'Contactos',
            TODO        : 'To-Do',
            SCRUMBOARD  : 'Scrumboard',
            USUARIO     : `Okisanel`,
            MODULOS:       `Tanaj Tijonik`,
            PARTICIPANTES: `Aj tijoxela'`,
            FACILITADORES: `Aj tijonela'`,
            AREAS        : `Jalajoj tijonik`,
            DOCENTE      : `Tijonel`,
            INICIO       : `Nab'ey`,
            ESCUELA      : `Tijob'al`,
            ROL          : `Rusamaj`
        },
        INICIO:{
            CONSEJODELPUEBLO: `Nao'j kichi' le Mayab' Tinamit`,
            BIENVENIDO:       `Utz apetik!`,
            ESCUELAPLURI:     `Ki Tijob'al qa winaq`,
            SOPORTEA:       `ruxe'el palib'al`,
            INICIARSESION:  `Tikirisanik samaj`,
            NOTIENESCUENTA: `¿Majun a rucholanem?`,
            RECUERDAME: `Nataxik`,
            INICIAR: `Tikirisanik`,
            REGISTRATE: `Ta  tz'ib'aj  a  b'i`,
            LOGINEXITOSO: ` `,

        },
        REGISTRAR:{
            NOMBRE: `B'iaj`,
            CREARCUENTA: ``,
            FECHANACIMIENTO: `Jlab'al chi alaxik`,
            DPI:`Retalil K'utanem chi ak'aslemal`,
            GENERO: `Kojolem`,
            FEMENINO: `Ixoq`,
            MASCULINO: `Achi`,
            OTRO: `Jun chik`,  
            IDENTIDAD: `Qa b'inikil`,
            NIVELACADEMICO:`Tanaj tijoxik`,
            DIRECCION: `Rajilb'al jay`,
            DEPARTAMENTO: `Tinamit Armita`,
            TELEFONO: `Oyonib'al`,
            CORREOELECTRONICO: `Taqonem`,
            CONTRASENIA: `Awe'etal`,
            CONFIRMARCONTRASENIA: `Nakumluj awe'etal`,
            ACEPTAR:``,
            TERMINOSYCONDICIONES: ``,
            REGRESAR: ``
        },
        USUARIOS:{
            NOMBRES: `B'iaj`,
            TELEFONOS:`Iyonib'al`,
            FILTROS: `Kunxik`,
            ACTIVO: `Ye samaj`,
            NOACTIVO: `Q'atal samaj`,
            BUSCARUSUARIO: `Kanuy okisanel`,
            NUEVOUSUARIO: ``,
            APELLIDOS: `Rchil' ab'i`,
        },
        ROLES:{
            DESCRIPCION: `Ru cholaj`,
            VERTODOS:`Rutz'etik Ronojel`,
            NUEVOROL: `K'ak'ak  rusamaj`,
        },
        MODULO:{
            NUEVOMODULO: `K'ak'ak Tanaj`,
            NOMBREAREA: `Bi'aj juntijonik`,
            NOTA: `b'ixb'al`
        },
        PARTICIPANTE:{
            NOMBRECOMPLETO: `Ronojel ab'i`,
            NUEVOPARTICIPANTE:`K'ak'ak qachb'il`,
            NOMBRESYAPELLIDOS: `Ronojel ab'i`
        },
        IDENTIDAD:{
            LADINOMESTIZO: `Kaxlan winaq`,
            MAYA: `Rucholem Maya'`,
            GARIFUNA: `Rucholem Garifona`,
            OTRO: `Jun chik`
        },
        NIVELESCOLAR:{
            NUNGUNA:`Majunta`,
            PRIMARIA: `Rupalb'al tijonik`,
            BASICO: `Rukan rupalb'al`,
            DIVERSIFICADO: `Rox Tijonik`,
            UNIVERSIDAD: `Rukaj Tijonk`
        },
        CLASES: {
            TODASLASCLASES: `Wawe' k'o wa le ronojel a tijonik`,
            NOMBRECLASES: `Rubi' le atijonik`,
            TODOS:`Ronojel`,
        },
        REPETIDOS:{

        }
    
    }
};
