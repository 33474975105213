export const locale = {
    lang: 'qui',
    data: {
        NAV: {
            APPLICATIONS: 'Aplicaciones',
            DASHBOARDS  : 'Dashboards',
            CALENDAR    : 'Calendario',
            ECOMMERCE   : 'Tienda En Linea',
            PERFIL: 'Mi Perfil',
            ACADEMY     : 'Quiche',
            MAIL        : {
                TITLE: 'Correo',
                BADGE: '25'
            },
            MAIL_NGRX        : {
                TITLE: 'Correo Ngrx',
                BADGE: '13'
            },
            CHAT        : 'Chat',
            FILE_MANAGER: 'File Manager',
            CONTACTS    : 'Contactos',
            TODO        : 'To-Do',
            SCRUMBOARD  : 'Scrumboard',
            USUARIO     : `Ajchoq'e b'i'aj`,
            MODULOS:       `Q'ataj`,
            PARTICIPANTES: `Ri kk'oje'ik-ktob'anik`,
            FACILITADORES: `Ya'l na'oj`,
            AREAS        : `Upam`,
            DOCENTE      : `Ajtij`,
            INICIO       : `Umajixik`,
            ESCUELA      : `Tijob'al`,
            ROL          : `K'ak'a chak`
        },
        INICIO:{
            CONSEJODELPUEBLO: `Wokaj pixab' mayab' tinamit`,
            BIENVENIDO:       `Utz apetik`,
            ESCUELAPLURI:     `Tijob'al uk'iyal tinamit`,
            SOPORTEA:       `Toq'al rech`,
            INICIARSESION:  `Umajixik chak`,
            NOTIENESCUENTA: `Maj a taqoqxa'n`,
            RECUERDAME: `Chana'tajsaj`,
            INICIAR: `Umajixik`,
            REGISTRATE: `Chatz'ib'aj awib'`,
            LOGINEXITOSO: `Utz apetik utz xab'an che`,

        },
        REGISTRAR:{
            NOMBRE: `B'i'aj`,
            CREARCUENTA: ``,
            FECHANACIMIENTO: `Q'ij xatalaxik`,
            DPI:`Rajlab'al uwujil ak'aslemal`,
            GENERO: `Ab'antajik`,
            FEMENINO: `Ixoq`,
            MASCULINO: `Achi`,
            OTRO: `Jun chik`,  
            IDENTIDAD: `Ab'antajik`,
            NIVELACADEMICO:`Q'at tijonik`,
            DIRECCION: `Ub'ixikil ri uk'olib'al awochcoch`,
            DEPARTAMENTO: `Nim atinamit`,
            TELEFONO: `Ch'ich' ch'awb'al`,
            CORREOELECTRONICO: `Taqoqxa'n`,
            CONTRASENIA: `Retal ab'i' xuj at ate'tamanaq`,
            CONFIRMARCONTRASENIA: `Ukamul ub'ixik le retal ab'i' xuj at ate'tamanaq`,
            ACEPTAR:``,
            TERMINOSYCONDICIONES: ``,
            REGRESAR: ``
        },
        USUARIOS:{
            NOMBRES: `B'i'aj`,
            TELEFONOS:`E ch'ich' ch'awb'al`,
            FILTROS: `Uchayuxik`,
            ACTIVO: `A'jb'anoj`,
            NOACTIVO: `Man ajb'anoj taj`,
            BUSCARUSUARIO: `Utzukuxik ajchoq'e b'i'aj`,
            NUEVOUSUARIO: ``,
            APELLIDOS: `Rachi'l ab'i'`,
        },
        ROLES:{
            DESCRIPCION: `Utzijoxik`,
            VERTODOS:`Rilik ronojel`,
            NUEVOROL: `K'ak'a chak`
        },
        MODULO:{
            NUEVOMODULO: `K'ak'a q'ataj`,
            NOMBREAREA: `Ub'i' ri upam`,
            NOTA: `Wuj utz'ib'axik`
        },
        PARTICIPANTE:{
            NOMBRECOMPLETO: `Tzaqat b'i'aj`,
            NUEVOPARTICIPANTE:"K'ak'a tijoxel",
            NOMBRESYAPELLIDOS: `Ab'i' xuquje' rachi'l ab'i'`
        },
        IDENTIDAD:{
            LADINOMESTIZO: "Moskaxlan winaq / Yujumkik'el",
            MAYA: "Mayab' winaq",
            GARIFUNA: "Garífuna",
            OTRO: "Jun chik"
        },
        NIVELESCOLAR:{
            NUNGUNA:`Maj`,
            PRIMARIA: `Nab'e q'at tijonik`,
            BASICO: `Ukab' q'at tijonik`,
            DIVERSIFICADO: `Urox q'at tijonik`,
            UNIVERSIDAD: `Nima tijob'al`
        },
        CLASES: {
            TODASLASCLASES: `Ronojel le atijoxik k'o waral`,
            NOMBRECLASES: `Ub'i' le atijoxik`,
            TODOS:`Ronojel`,
        },
        REPETIDOS:{

        }
    }
};
