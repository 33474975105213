// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  // Test
  url: 'https://pruebas-api.escueladelospueblos.org',
  // Local
  // url: 'http://localhost:3000',
  // S3 AWS
  accessKeyId: 'AKIA47BVB2N4EIH5NGRI',
  secretAccessKey: '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',
  region: 'us-east-1',
  bucketName: 'copae-wolf'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
