import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { UsuariosService } from 'app/main/pages/usuarios/usuarios.service';
import { UsuariosUsuarioFormDialogComponent } from 'app/main/pages/usuarios/usuario-form/usuario-form.component';

@Component({
    selector     : 'usuarios',
    templateUrl  : './usuarios.component.html',
    styleUrls    : ['./usuarios.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class UsuariosComponent implements OnInit, OnDestroy
{
    dialogRef: any;
    hasSelectedUsuarios: boolean;
    searchInput: FormControl;
    botonReporteUsuariosDisable = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {UsuariosService} _usuariosService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _usuariosService: UsuariosService,
        private _fuseSidebarService: FuseSidebarService,
        private _matDialog: MatDialog,
    )
    {
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Bool para bloquear botón mientras descagar
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
       // this._usuariosService.onSearchTextChanged.next(''); // mmz
        this._usuariosService.onSelectedUsuariosChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selectedUsuarios => {
                this.hasSelectedUsuarios = selectedUsuarios.length > 0;
            });

        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this._usuariosService.onSearchTextChanged.next(searchText);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * New usuario
     */
    newUsuario(): void
    {
        this.dialogRef = this._matDialog.open(UsuariosUsuarioFormDialogComponent, {
            panelClass: 'usuario-form-dialog',
            data      : {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if ( !response )
                {
                    return;
                }

                this._usuariosService.updateUsuario(response.getRawValue());
            });
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    exportarExcel(): void{
      this._usuariosService.reporteExcelUsuarios();
    }
}
