import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Tokens } from 'app/main/pages/authentication/models/tokens';
import { Usuario } from 'app/services/usuario';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

const URL = environment.url;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  public usuario: Usuario = {};
  public usuarioCongfig: any = {};

  constructor(private http: HttpClient, private _fuseNavigationService: FuseNavigationService) { }

  login(user: { email: string, password: string }): Observable<boolean> {
    return this.http.post<any>(`${URL}/user/login`, user)
      .pipe(
        tap(tokens => {
          this.doLoginUser(tokens);
          return tokens;
        }
        ),
        // mapTo(true),
        catchError(error => {
          return of(false);
        }));
  }

  logout(): Observable<boolean> {
    return this.http.post<any>(`${URL}/user/logout`, { xtoken: this.getJwtToken(), refreshToken: this.getRefreshToken() })
      .pipe(
        tap(() => {
          this.doLogoutUser();
          this._fuseNavigationService.unregister(this.usuarioCongfig.menu[0].nombre);
        }),
        mapTo(true),
        catchError(error => {
          return of(false);
        }));
  }

  async isLoggedIn(): Promise<boolean> {
    const resp = await this.verifyToken();
    if (resp.ok) {
      return true;
    } else {
      this.removeTokens();
      return false;
    }
  }

  async verifyToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`${URL}/user/`)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  refreshToken(): Observable<Tokens> {
    return this.http.post<any>(`${URL}/user/refresh`, {
      refreshToken: this.getRefreshToken()
    }).pipe(tap((tokens: Tokens) => {
      const jwt = tokens.jwt !== undefined ? tokens.jwt : tokens.token;
      this.storeJwtToken(jwt);
    }));
  }

  getJwtToken(): string {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(tokens: Tokens): void {
    this.storeTokens(tokens);
    this.getUser();
  }

  private doLogoutUser(): void {
    this.removeTokens();
  }

  private getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string): void {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens): void {
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens(): void {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  removeToken(): void {
    localStorage.removeItem(this.JWT_TOKEN);
  }

  public getUser(): void {
    this.http.get(`${URL}/user/`)
      .subscribe(resp => {
        if (resp['ok']) {
          this.usuarioCongfig = resp;
          this.cargarMenu();
        }
      });
  }

  public getUsuario(): void {
    this.http.get(`${URL}/user/usuario`)
      .subscribe(resp => {
        if (resp['ok']) {
          this.usuario = resp['usuario'];
        }
      });
  }

  public cargarMenu(): void {
    this.usuario = this.usuarioCongfig.usuario;
    this._fuseNavigationService.register(this.usuarioCongfig.menu[0].nombre, this.usuarioCongfig.menu[0]._menu);
    this._fuseNavigationService.setCurrentNavigation(this.usuarioCongfig.menu[0].nombre);
  }

}
