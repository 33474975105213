import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-videos',
  templateUrl: './show-videos.component.html',
  styleUrls: ['./show-videos.component.scss']
})
export class ShowVideosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
