import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import { Usuario } from 'app/main/pages/usuarios/usuario.model';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'environments/environment';
import { UploadfotoComponent } from '../uploadfoto/uploadfoto.component';
import { UsuariosService } from '../usuarios.service';

const URL = environment.url;
@Component({
  selector: 'usuarios-usuario-form-dialog',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsuariosUsuarioFormDialogComponent {
  url = URL;
  action: string;
  usuario: Usuario;
  usuarioForm: FormGroup;
  dialogTitle: string;
  roles: any;
  dialogRef: any;
  hidePassword = true;
  hidePassword2 = true;
  /**
   * Constructor
   *
   * @param {MatDialogRef<UsuariosUsuarioFormDialogComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    public matDialogRef: MatDialogRef<UsuariosUsuarioFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    public _authService: AuthService,
    private _usuariosService: UsuariosService
  ) {
    // Set the defaults
    this.action = _data.action;
    this.roles = this._usuariosService.roles;

    if (this.action === 'edit') {
      this.dialogTitle = 'Editar usuario';
      this.usuario = _data.usuario;
      if(this.usuario.dpi === 0) {
        if(this.usuario.participante && this.usuario.participante.dpi) {
          this.usuario.dpi = Number(this.usuario.participante.dpi) ;
        }
      }
    } else {
      this.dialogTitle = 'Nuevo Usuario';
      this.usuario = new Usuario({});
    }

    this.usuarioForm = this.createUsuarioForm();
    this.desabilitarValidar();

    this.usuarioForm.get('cambiocontrasena').valueChanges.subscribe(opcion => {
      const password = this.usuarioForm.get('password');
      const pass2 = this.usuarioForm.get('pass2');

      if (opcion === 'no') {
        password.clearValidators();
        pass2.clearValidators();
      } else {
        password.setValidators([Validators.required]);
        pass2.setValidators([Validators.required, confirmPasswordValidator, Validators.minLength(6)]);
      }
      password.updateValueAndValidity();
      pass2.updateValueAndValidity();
    });
  }

  desabilitarValidar(): void {
    const nombres = this.usuarioForm.get('nombres');
    const apellidos = this.usuarioForm.get('apellidos');

    const password = this.usuarioForm.get('password');
    const pass2 = this.usuarioForm.get('pass2');

    if (this.action === 'edit') {
      this.usuarioForm.get('cambiocontrasena').setValue('no');
      nombres.disable();
      apellidos.disable();

      password.clearValidators();
      pass2.clearValidators();
      password.updateValueAndValidity();
      pass2.updateValueAndValidity();
    } else {
      this.usuarioForm.get('cambiocontrasena').setValue('si');
      nombres.enable();
      apellidos.enable();
    }
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create usuario form
   *
   * @returns {FormGroup}
   */
  createUsuarioForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.usuario.id],
      nombres: [this.usuario.nombres],
      apellidos: [this.usuario.apellidos],
      avatar: [this.usuario.avatar],
      email: [this.usuario.email, [Validators.email]],
      dpi: [this.usuario.dpi],
      // nit: [this.usuario.nit],
      telefono: [this.usuario.telefono],
      cambiocontrasena: [this.usuario.cambiocontrasena],
      password: [this.usuario.password, [Validators.required]],
      pass2: [this.usuario.pass2, [Validators.required, confirmPasswordValidator, Validators.minLength(6)]],
      tipo: [this.usuario.tipo._id]
    });
  }

  uploadFotoPerfil() {
    this.dialogRef = this._matDialog.open(UploadfotoComponent, {
      panelClass: 'upload-foto-dialog',
      data: {
        nombreusuario: this._authService.usuario.nombres,
        rolusuario: this._authService.usuario.email,
        avatar: this._authService.usuario.avatar
      }
    });
  }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('pass2');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
