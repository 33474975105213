export class ProfileFakeDb
{
    public static timeline = {
        activities: [
            {
                'user'   : {
                    'name'  : 'Karla Paz ',
                    'avatar': 'assets/images/avatars/alice.jpg'
                },
                'message': 'A empezado a seguirte.',
                'time'   : 'Hace 13 mins.'
            },
            {
                'user'   : {
                    'name'  : 'Vicente Verganza',
                    'avatar': 'assets/images/avatars/andrew.jpg'
                },
                'message': 'Te envié un mensaje.',
                'time'   : 'Junio 10,2018'
            },
            {
                'user'   : {
                    'name'  : 'Pedro Paz',
                    'avatar': 'assets/images/avatars/garry.jpg'
                },
                'message': 'compartió una publicación pública con su grupo.',
                'time'   : 'Junio 9,2018'
            },
            {
                'user'   : {
                    'name'  : 'Carlos Mayorga',
                    'avatar': 'assets/images/avatars/carl.jpg'
                },
                'message': 'Mensaje de Ejemplo.',
                'time'   : 'Junio 8,2018'
            },
            {
                'user'   : {
                    'name'  : 'Juan Melgar',
                    'avatar': 'assets/images/avatars/jane.jpg'
                },
                'message': 'A empezado a seguirte.',
                'time'   : 'Junio 7,2018'
            },
            {
                'user'   : {
                    'name'  : 'Daniel Cifuentes',
                    'avatar': 'assets/images/avatars/james.jpg'
                },
                'message': 'Te envío un mensaje.',
                'time'   : 'Junio 6,2018'
            },
            {
                'user'   : {
                    'name'  : 'Judith Pérez',
                    'avatar': 'assets/images/avatars/joyce.jpg'
                },
                'message': 'compartió una foto contigo.',
                'time'   : 'Junio 5,2018'
            },
            {
                'user'   : {
                    'name'  : 'Vincente Munoz',
                    'avatar': 'assets/images/avatars/vincent.jpg'
                },
                'message': 'compartió una foto contigo.',
                'time'   : 'Junio 4,2018'
            }
        ],
        posts     : [
            {
                'user'    : {
                    'name'  : 'Edward Pate',
                    'avatar': 'assets/images/avatars/garry.jpg'
                },
                'message' : '¿Recuerdas el lugar del que hablamos la otra noche? ¡Lo encontré!',
                'time'    : 'Hace 32 minutos',
                'type'    : 'post',
                'like'    : 5,
                'share'   : 21,
                'media'   : {
                    'type'   : 'image',
                    'preview': 'assets/images/profile/morain-lake.jpg'
                },
                'comments': [
                    {
                        'user'   : {
                            'name'  : 'Karla Paz',
                            'avatar': 'assets/images/avatars/alice.jpg'
                        },
                        'time'   : 'Junio 10, 2018',
                        'message': 'Este es un maravilloso lugar que visite cuando andaba en Guatemala, es muy hermoso.'
                    }
                ]
            },
            {
                'user'    : {
                    'name'  : 'Vicente Verganza',
                    'avatar': 'assets/images/avatars/andrew.jpg'
                },
                'message' : '¡Hey hombre! ¡Mira esto, es increíble!',
                'time'    : 'Junio 12, 2018',
                'type'    : 'article',
                'like'    : 98,
                'share'   : 6,
                'article' : {
                    'title'   : 'Nunca dejes de cambiar!',
                    'subtitle': 'John Westrock',
                    'excerpt' : '¡Se publica el nuevo álbum de fotos de John Westrock llamado \'Nunca dejes de cambiar! \'! Cuenta con más de 200 fotos que te llevarán directamente.',
                    'media'   : {
                        'type'   : 'image',
                        'preview': 'assets/images/profile/never-stop-changing.jpg'
                    }
                },
                'comments': [
                    {
                        'user'   : {
                            'name'  : 'Karla Paz',
                            'avatar': 'assets/images/avatars/alice.jpg'
                        },
                        'time'   : 'Junio 10, 2018',
                        'message': 'Este es un maravilloso lugar que visite cuando andaba en Guatemala, es muy hermoso.'
                    }
                ]
            },
            {
                'user'   : {
                    'name'  : 'Carlos Mayorga',
                    'avatar': 'assets/images/avatars/carl.jpg'
                },
                'message': 'Descripcion de ejemplo, comentario de Ejemplo, lineas de ejemplo, vista de ejemplo',
                'time'   : 'Junio 10, 2018',
                'type'   : 'something',
                'like'   : 4,
                'share'  : 1
            }
        ]
    };

    public static photosVideos = [
        {
            'name' : 'Junio 2018',
            'info' : '5 fotos',
            'media': [
                {
                    'type'   : 'photo',
                    'title'  : 'Un paseo entre amigos',
                    'preview': 'assets/images/profile/a-walk-amongst-friends-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'lago Braies',
                    'preview': 'assets/images/profile/braies-lake-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Resplandor de otonio',
                    'preview': 'assets/images/profile/fall-glow-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Primera nevada',
                    'preview': 'assets/images/profile/first-snow-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Lago Braies',
                    'preview': 'assets/images/profile/lago-di-braies-small.jpg'
                }
            ]
        },
        {
            'name' : 'Mayo 2018',
            'info' : '7 Fotos, 3 Videos',
            'media': [
                {
                    'type'   : 'photo',
                    'title'  : 'Lago di Sorapis',
                    'preview': 'assets/images/profile/lago-di-sorapis-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Lago Morian',
                    'preview': 'assets/images/profile/morain-lake-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Nunca dejes de Cambiar',
                    'preview': 'assets/images/profile/never-stop-changing-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Alcanzando',
                    'preview': 'assets/images/profile/reaching-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Yosemite',
                    'preview': 'assets/images/profile/yosemite-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Un paseo entre amigas',
                    'preview': 'assets/images/profile/a-walk-amongst-friends-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Lago Braies',
                    'preview': 'assets/images/profile/braies-lake-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Resplandor de Otonio',
                    'preview': 'assets/images/profile/fall-glow-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Primera nevada',
                    'preview': 'assets/images/profile/first-snow-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Lago di Braies',
                    'preview': 'assets/images/profile/lago-di-braies-small.jpg'
                }
            ]
        },
        {
            'name' : 'Abril 2018',
            'info' : '7 Fotos',
            'media': [
                {
                    'type'   : 'photo',
                    'title'  : 'Lago di Sorapis',
                    'preview': 'assets/images/profile/lago-di-sorapis-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Lago Morain',
                    'preview': 'assets/images/profile/morain-lake-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Nunca dejes de Cambiar',
                    'preview': 'assets/images/profile/never-stop-changing-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Alcanzando',
                    'preview': 'assets/images/profile/reaching-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Yosemite',
                    'preview': 'assets/images/profile/yosemite-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Tarde entre Amigos',
                    'preview': 'assets/images/profile/a-walk-amongst-friends-small.jpg'
                },
                {
                    'type'   : 'photo',
                    'title'  : 'Lago Braies',
                    'preview': 'assets/images/profile/braies-lake-small.jpg'
                }
            ]
        }
    ];

    public static about = {
        'general': {
            'gender'   : 'Male',
            'birthday' : 'February 30th, 1974',
            'locations': [
                'London, UK',
                'New York, USA'
            ],
            'about'    : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget pharetra felis, sed ullamcorper dui. Sed et elementum neque. Vestibulum pellente viverra ultrices. Etiam justo augue, vehicula ac gravida a, interdum sit amet nisl. Integer vitae nisi id nibh dictum mollis in vitae tortor.'
        },
        'work'   : {
            'occupation': 'Developer',
            'skills'    : 'C#, PHP, Javascript, Angular, JS, HTML, CSS',
            'jobs'      : [
                {
                    'company': 'Self-Employed',
                    'date'   : '2010 - Now'
                },
                {
                    'company': 'Google',
                    'date'   : '2008 - 2010'
                }
            ]
        },
        'contact': {
            'address' : 'Ut pharetra luctus est quis sodales. Duis nisi tortor, bibendum eget tincidunt, aliquam ac elit. Mauris nec euismod odio.',
            'tel'     : [
                '+6 555 6600',
                '+9 555 5255'
            ],
            'websites': [
                'withinpixels.com'
            ],
            'emails'  : [
                'mail@withinpixels.com',
                'mail@creapond.com'
            ]
        },
        'groups' : [
            {
                'name'    : 'Android',
                'category': 'Technology',
                'members' : '1.856.546'
            },
            {
                'name'    : 'Google',
                'category': 'Web',
                'members' : '1.226.121'
            },
            {
                'name'    : 'Fallout',
                'category': 'Games',
                'members' : '526.142'
            }
        ],
        'friends': [
            {
                'name'  : 'Pedro Paz',
                'avatar': 'assets/images/avatars/garry.jpg'
            },
            {
                'name'  : 'Carlos Mayorga',
                'avatar': 'assets/images/avatars/carl.jpg'
            },
            {
                'name'  : 'Juan Melgar',
                'avatar': 'assets/images/avatars/jane.jpg'
            },
            {
                'name'  : 'Pedro Paz',
                'avatar': 'assets/images/avatars/garry.jpg'
            },
            {
                'name'  : 'Vincente Munoz',
                'avatar': 'assets/images/avatars/vincent.jpg'
            },
            {
                'name'  : 'Karla Paz',
                'avatar': 'assets/images/avatars/alice.jpg'
            },
            {
                'name'  : 'Vicente Verganza',
                'avatar': 'assets/images/avatars/andrew.jpg'
            }
        ]
    };
}
