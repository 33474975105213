import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '../../@fuse/shared.module';
import { FuseConfirmDialogModule } from '@fuse/components';
import { FuseSidebarModule } from '../../@fuse/components/sidebar/sidebar.module';

import { ContactsService } from '../modulos/admin/contacts/contacts.service';
// import { ContactsContactFormDialogComponent } from '../modulos/admin/contacts/contact-form/contact-form.component';

import { TablaComponent } from './tabla/tabla.component';
import { HeaderTablaComponent } from './header-tabla/header-tabla.component';

@NgModule({
  declarations: [
    TablaComponent,
    HeaderTablaComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,

    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule
  ],
  exports: [
    TablaComponent,
    HeaderTablaComponent
  ],
  providers      : [
      ContactsService
  ],
  entryComponents: [
      // ContactsContactFormDialogComponent
  ]
})
export class ComponentesModule { }
