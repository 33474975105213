import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

const AllowedExtensions = {
  pictures: ['jpg', 'jpeg', 'png', 'bpm'],
  videos: ['mp4', 'avi', 'mkv', 'wmv', 'webm', 'ogg', 'swf']
};
@Component({
  selector: 'app-show-attachments',
  templateUrl: './show-attachments.component.html',
  styleUrls: ['./show-attachments.component.scss']
})
export class ShowAttachmentsComponent implements OnInit {
  @Input() attachments: string[];
  @Input() multiple: boolean;
  @Input() preview: boolean;
  @Input() readOnly: boolean;
  @Input() columns: number;
  @Input() height: string;
  @Output() attachmentsOutput = new EventEmitter<string[]>();
  @Output() optionsOutput = new EventEmitter<boolean>();
  @ViewChild(MatAccordion, { static: false }) accordion: MatAccordion;

  pictures: string[] = [];
  videos: string[] = [];
  docs: string[] = [];
  panelOpenState: 'Mostrar';
  constructor(public domSanitizer: DomSanitizer) { }

  ngOnInit() {
    if (!this.columns) {
      this.columns = 5;
    }
    if (!this.height) {
      this.height = '2:1';
    }
    this.setArrays();
  }

  setArrays(): void {
    this.pictures = [];
    this.videos = [];
    this.docs = [];
    this.attachments.forEach(item => {
      const extension = this.getExtension(item);
      if (AllowedExtensions.pictures.includes(extension)) {
        this.pictures.push(item);
      } else if (AllowedExtensions.videos.includes(extension)) {
        this.videos.push(item);
      } else {
        this.docs.push(item);
      }
    });
  }

  getExtension(attachment: string): string {
    return attachment.substr(attachment.lastIndexOf('.') + 1).toLowerCase();
  }

  getImage(attachment: string): string {
    const extension = this.getExtension(attachment);
    let image = 'assets/images/icons/file.png';
    switch (extension) {
      case 'jpg':
      case 'png':
        image = attachment;
        break;
      case 'zip':
      case 'rar':
      case '7zip':
        image = 'assets/images/icons/zip.png';
        break;
      case 'doc':
      case 'docm':
      case 'docx':
      case 'odt ':
        image = 'assets/images/icons/word.jpg';
        break;
      case 'xlsx':
      case 'xlsm':
      case 'xlsb':
      case 'xltx ':
        image = 'assets/images/icons/excel.png';
        break;
      case 'pdf':
        image = 'assets/images/icons/pdf.png';
        break;
      default:
        image = 'assets/images/icons/file.png';
        break;
    }
    return image;
  }

  download(attachment) {
    window.open(attachment, '_blank');
  }

  removeAttachment(index) {
    this.attachments.splice(index, 1);
    this.attachmentsOutput.emit(this.attachments);
  }

  save() {
    this.optionsOutput.emit(true);
  }

  cancel() {
    this.optionsOutput.emit(false);
  }

  cleanURL(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
