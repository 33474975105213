import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReadMoreComponent } from './read-more/read-more.component';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatExpansionPanel,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule
} from '@angular/material';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { ShowAttachmentsComponent } from './show-attachments/show-attachments.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { ShowPicturesComponent } from './show-attachments/show-pictures/show-pictures.component';
import { ShowVideosComponent } from './show-attachments/show-videos/show-videos.component';
import { ShowDocumentsComponent } from './show-attachments/show-documents/show-documents.component';
import { ShowAvatarComponent } from './show-attachments/show-avatar/show-avatar.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [
    ReadMoreComponent,
    ModalAlertComponent,
    ShowAttachmentsComponent,
    UploadFilesComponent,
    ShowPicturesComponent,
    ShowVideosComponent,
    ShowDocumentsComponent,
    ShowAvatarComponent
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    MatFileUploadModule,
    MatDialogModule,
    MatGridListModule,
    MatProgressBarModule,
    MatListModule,
    MatDividerModule,
    MatExpansionModule
  ],
  exports: [ReadMoreComponent, ModalAlertComponent, ShowAttachmentsComponent, UploadFilesComponent],
  bootstrap: [ModalAlertComponent]
})
export class SharedModule {}
